(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('ProductAnalysisController', ProductAnalysisController);

    ProductAnalysisController.$inject = ['$scope', '$state', 'ParseLinks', 'MessageService', 'SalesOrder', 'XlsxService', 'Product',
     'Store', 'DateUtils','ProductXmls','$http'];

    function ProductAnalysisController ($scope, $state, ParseLinks, MessageService, SalesOrder, XlsxService, Product,
        Store, DateUtils,ProductXmls,$http) {
        var vm = this;
        vm.datePickerOpenStatus = {};
        vm.openCalendar =  openCalendar;
        vm.datePickerOpenStatus.startTime = false;
        vm.datePickerOpenStatus.endTime = false;
        vm.transition = transition;
        vm.search = search;
        vm.clear = clear;
        vm.downLoadFile = downLoadFile;
        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }
        //获取商户
        Store.getCurrent({},function (res) {
            vm.data = {
                storeId:res.data.id,
                startDate:'',
                endDate:'',
                productId:'',
                productXmlId:'',
                editorType:'',
                page:0,
                pageSize:20
            };
            productInit();
            getProductXmls();
            init();
        });
        vm.page = 1;
        //编辑器
        vm.editorTypes = [
            {name:'简易编辑器', id:'BOOK_JUNIOR'},
            {name:'高级编辑器', id:'BOOK_SENIOR'},
            {name:'万物编辑器', id:'POSTER'},
            {name:'相册编辑器', id:'NEW_EDITOR'},
            {name:'冲印编辑器', id:'PICTURE'},
            {name:'卡片编辑器', id:'PICTURE_PRO'},
            {name:'证件照编辑器', id:'PHOTO_EDITOR'},
            {name:'表单编辑器', id:'FORM_EDITOR'},
            {name:'动态台历编辑器', id:'CALENDAR_EDITOR'},
        ]
        //获取商品
        function productInit(){
            var items = [{key: "prototype", op: "!=", value: 'Original'},
                {key: "storeId", op: "=", value: vm.data.storeId},
            ];
            Product.getAllByCondition2({
                items:items
            },function(res){
                if(res.status == 200){
                    vm.products = res.data;
                }else {
                    MessageService.error(res.message);
                    return
                }
            })
        }
        $scope.productChange = function () {
            vm.data.productId = vm.product ? vm.product.id : "";
            getProductXmls();
        }
        //获取模板
        function getProductXmls() {
            var items = [
                {key: "storeId", op: "=", value: vm.data.storeId},
                {key: "status", op: "=", value: 'Approved'}
            ];
            if(vm.product){
                items.push({key: "category.id", op: "=", value: vm.product.categoryId})
            }

            ProductXmls.getAllByCondition({
                items: items
            }, function (res) {
                if(res.status == 200){
                    vm.productXmlsAll = res.data;
                }else {
                    MessageService.error(res.message);
                }
            });
        }
        function init() {
            vm.tableData = [];
            vm.data.page = vm.page-1;
            // vm.data.startDate = DateUtils.convertLocalDateToServer(vm.startDate);
            // vm.data.endDate = DateUtils.convertLocalDateToServer(vm.endDate);
            if (!!vm.endDate) {
                vm.data.endDate = new Date(Date.parse(vm.endDate));
            };
            if (!!vm.startDate) {
                vm.data.startDate =  new Date(Date.parse(vm.startDate));
            };
            SalesOrder.byConditionPage(vm.data,function (res) {
                if(res.status == 200){
                    vm.tableData = res.data.productVMS;
                    console.log(vm.tableData)
                    vm.totalItems = res.data.count;
                }else {
                    MessageService.error(res.message);
                }
            })
        }
        function transition() {
            init();
        }
        function search() {
            vm.page = 1;
            init();
        }
        function clear() {
            vm.page = 1;
            vm.product = null;
            vm.data = {
                storeId:vm.data.storeId,
                startDate:'',
                endDate:'',
                productId:'',
                productXmlId:'',
                editorType:'',
                page:0,
                pageSize:20
            };
            vm.startDate = "";
            vm.endDate = "";
            init();
        }
        function downLoadFile(){
            vm.data.page = vm.page-1;
            // vm.data.startDate = DateUtils.convertLocalDateToServer(vm.startDate);
            // vm.data.endDate = DateUtils.convertLocalDateToServer(vm.endDate);
            if (!!vm.endDate) {
                vm.data.endDate = new Date(Date.parse(vm.endDate));
            };
            if (!!vm.startDate) {
                vm.data.startDate =  new Date(Date.parse(vm.startDate));
            };
            $http({
                url: 'service/api/sales-orders/byConditionPage/download',
                method: "GET",
                params: vm.data,
                headers: {
                    'Content-type': 'application/json',
                },
                responseType: 'blob'
            }).success(function (data, status, headers, config) {
                var blob = new Blob([data], {type: "application/vnd.ms-excel"});
                var objectUrl = URL.createObjectURL(blob);
                var a = document.createElement('a');
                document.body.appendChild(a);
                a.setAttribute('style', 'display:none');
                a.setAttribute('href', objectUrl);
                var filename = "商品模板统计" +DateUtils.convertLocalDateToServer(Date.now())+ ".xls";
                a.setAttribute('download', filename);
                a.click();
                URL.revokeObjectURL(objectUrl);
                document.body.removeChild(a);
            }).error(function (data, status, headers, config) {
            });
        }
        vm.editorTypeStatus = editorTypeStatus;
        function editorTypeStatus(target) {

            // 下面的字段内容与后台枚举[OrderStatus]字段对应
            switch (target) {
                case "BOOK_SENIOR":
                    return "高级编辑器";
                case "BOOK_JUNIOR":
                    return "简易编辑器";
                case "PICTURE":
                    return "冲印编辑器";
                case "PICTURE_PRO":
                    return "卡片编辑器";
                case "POSTER":
                    return "万物编辑器";
                case "NEW_EDITOR":
                    return "相册编辑器";
                case "DIY_EDITOR":
                    return "DIY编辑器";
                case "FORM_EDITOR":
                    return "表单编辑器";
                case "CALENDAR_EDITOR":
                    return "动态台历编辑器";
                case "PHOTO_EDITOR":
                    return "证件照编辑器";
                default:
                    return "";
            }
        }
    }
})();
